import './App.css';
import Navbar from './components/navbar';
import Intro from './components/intro';
import Separator from './components/separator';
import About from './components/about';
import TechStack from './components/techstack';
import Projects from './components/projects';
import Modal from './components/modal';
import Footer from './components/footer';
import {WorkExperience,Education} from './components/experience'
import PerlinNoiseBackground from "./components/background/perlinNoiseBackground";

function App() {
  return (
    <>
    <Navbar/>
    <div className="App" id="home">
      <PerlinNoiseBackground />
      <Intro/>
      <Separator title="Who am I ?" id="about"/>
      <About/>
      <Separator title="Work Experience" id="work-experience"/>
      <WorkExperience/>
      <Separator title="Education" id="education"/>
      <Education/>
      <Separator title="Projects" id="projects"/>
      <Projects/>
      <div style={{ height: "15vh" }}></div>
      {/* <Separator title="Tech Stack" id="skills"/>
      <Modal
        key={"techstack"}
        component={<TechStack/>}
        data={{title:"Tech Stack",md_file:"techstack.md"}}
      /> */}
    </div>
    <Footer/>
    </>
  );
}
export default App;
