import React from "react";
import "./style.css";

const About = () => {
  return (
    <div className="AboutText">
      <p>
        👋 Hello! I'm Gatien, a passionate engineer with a solid foundation in
        physics and machine learning.
      </p>
      <p>
        🧮 From 2019 to 2021, I immersed myself in advanced mathematics and
        physics to prepare for the competitive entrance exams for French
        engineering schools.
      </p>
      <p>
        👨‍🔬 I then joined Télécom Physique Strasbourg (2021-2024) and the
        University of Strasbourg (2022-2024), where I earned a dual degree in
        computer science and physics, specializing in Image Processing and
        Machine Learning.
      </p>
      <p>
        🚀 I am currently working at{" "}
        <a href="https://visioncheckout.com">auvisus</a> as a Machine Learning
        Engineer.
      </p>
    </div>
  );
};
export default About;
