import { useEffect, useRef } from "react";
import * as THREE from "three";
import "./style.css";

const vertexShader = `
  void main() {
    gl_Position = vec4(position, 1.0);
  }
`;
const fragmentShader = `
  precision mediump float;

  uniform vec2 u_resolution;
  uniform float u_time;

  vec2 gradient(vec2 position) {
      float angle = fract(sin(dot(position, vec2(12.9898, 78.233))) * 43758.5453) * 2.0 * 3.14159;
      return vec2(cos(angle), sin(angle));
  }

  float heightValue(vec2 globalXY) {
      vec2 localXY = fract(globalXY);
      
      vec2 g00 = gradient(floor(globalXY) + vec2(0.0, 0.0));
      vec2 g01 = gradient(floor(globalXY) + vec2(0.0, 1.0));
      vec2 g10 = gradient(floor(globalXY) + vec2(1.0, 0.0));
      vec2 g11 = gradient(floor(globalXY) + vec2(1.0, 1.0));
      
      vec2 offset00 = localXY - vec2(0.0, 0.0);
      vec2 offset01 = localXY - vec2(0.0, 1.0);
      vec2 offset10 = localXY - vec2(1.0, 0.0);
      vec2 offset11 = localXY - vec2(1.0, 1.0);
      
      float d00 = dot(g00, offset00);
      float d01 = dot(g01, offset01);
      float d10 = dot(g10, offset10);
      float d11 = dot(g11, offset11);
      
      vec2 localXYEased = smoothstep(0.0, 1.0, localXY);
      float interpolated = mix(
          mix(d00, d10, localXYEased.x),
          mix(d01, d11, localXYEased.x),
          localXYEased.y
      );
      
      return (interpolated + 0.707) / 1.414;
  }

  float contourLine(vec2 position, float contourCount, float contourShift) {   
      float h = heightValue(position);
      float contourOffset = fract(h * contourCount + contourShift);
      float nearestContourVerticalDistance = min(contourOffset, 1.0 - contourOffset) / contourCount;
      float screenSpaceSlope = length(vec2(dFdx(h), dFdy(h)));
      float screenSpaceDistance = nearestContourVerticalDistance / screenSpaceSlope;
      return 1.0 - smoothstep(0.0, 1.0, screenSpaceDistance);
  }

  void main() {
      vec2 fragCoord = gl_FragCoord.xy;
      vec2 position = fragCoord / u_resolution.y * 5.0;
      
      float h = heightValue(position);
      float contour = contourLine(position, 4.0, u_time / 50.0);
      
      vec3 grayscaleColor = vec3(0.1568, 0.2196, 0.2862)*0.3 + h * vec3(0.1568, 0.2196, 0.2862) * 0.8 *(1.0 - contour)
                          + vec3(0.8) * contour;
      
      gl_FragColor = vec4(grayscaleColor, 1.0);
  }
`;

const PerlinNoiseBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, 10);
    camera.position.z = 1;

    const renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setSize(window.innerWidth, window.innerHeight);

    const geometry = new THREE.PlaneGeometry(2, 2);

    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        u_time: { value: 0.0 },
        u_resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
      },
    });

    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);

    const animate = (time) => {
      requestAnimationFrame(animate);
      material.uniforms.u_time.value = time * 0.001;
      renderer.render(scene, camera);
    };
    animate();

    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      material.uniforms.u_resolution.value.set(
        window.innerWidth,
        window.innerHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      renderer.dispose();
    };
  }, []);

  return <canvas ref={canvasRef} className="perlin-noise-background" />;
};

export default PerlinNoiseBackground;
