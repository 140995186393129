import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Github from "../svg/github";
import './style.css'
import Markdown from '../markdown';

const Modal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch("/markdown/"+props.data.md_file)
    .then((res) => res.text())
    .then((text) => {
      if (text.startsWith("<")) {
        setMarkdown("## Sorry, this project has not been documented yet.");
      }else{
        setMarkdown(text);
      }
    });
  }, [props.data.md_file]);
  

  const handleOpenModal = () => {
    setShowModal(true);
  }
  
  const handleCloseModal = () => {
    setShowModal(false);
  }


  

  return (
    <div>
      <div onClick={handleOpenModal}>{props.component}</div>
      <ReactModal 
         isOpen={showModal}
         contentLabel="onRequestClose Example"
         onRequestClose={handleCloseModal}
         className="Modal"
         overlayClassName="ModalOverlay"
         ariaHideApp={false}
      >
        <div className='ModalHeader'>
          <div className='ModalHeaderTitleContainer'>
            {props.data.git_repo && (
              <a className='github-icon' href={props.data.git_repo} target="_blank" rel="noreferrer">
                <img src="/assets/github.png" alt="github"/>
              </a>
            )}
            <h1 className='ModalHeaderTitle'>{props.data.title}</h1>
          </div>

          <button className='close' onClick={handleCloseModal}>Close</button>
        </div>
        <div className="ModalSeparatorLine"/>
        <Markdown className='ModalMarkdown'>{markdown}</Markdown>
      </ReactModal>
    </div>
  );
}

export default Modal;