import React from "react";
import "./style.css";

const Navbar = () => {
  return (
    <nav className="Navbar">
      <div className="NavLeft">{/* Gatien CHENU */}</div>

      <div className="NavMiddle" />

      <ul className="NavRight">
        <a href="#home" className="NavElement">
          Home
        </a>
        <div className="NavSeparator">|</div>
        <a href="#about" className="NavElement">
          About
        </a>
        <div className="NavSeparator">|</div>

        <a href="#work-experience" className="NavElement">
          Work Experience
        </a>
        <div className="NavSeparator">|</div>

        <a href="#education" className="NavElement">
          Education
        </a>
        <div className="NavSeparator">|</div>

        <a href="#projects" className="NavElement">
          Projects
        </a>
        {/* <div className="NavSeparator">|</div> */}

        {/* <a href="#skills" className="NavElement">
          Skills
        </a> */}
      </ul>
    </nav>
  );
};
export default Navbar;
