import React from "react";
import "./style.css";
import Modal from "../modal";
const projects_data = [
  // {
  //   id: "projects_0",
  //   title: "Cartobat",
  //   image: "/assets/projects/cartobat.png",
  //   description:
  //     "Python package for Bluetooth Low Energy indoor localization data analysis and visualization.",
  //   md_file: "cartobat.md",
  //   git_repo: "https://github.com/gatienc/Cartobat",
  // },
  {
    id: "projects_0",
    title: "NixFlakes",
    image: "/assets/projects/nixos.png",
    description: "My NixOS configuration files, for all my computer.",
    md_file: "nixflakes.md",
    git_repo: "https://github.com/gatienc/nixflakes",
  },
  {
    id: "projects_1",
    title: "InsidePSBS",
    image: "/assets/projects/insidepsbs.png",
    description:
      "Mobile app designed as a social network for Télécom Physique Strasbourg.",
    md_file: "insidepsbs.md",
    git_repo: "https://github.com/info-telecom-strasbourg/InsidePSBS",
  },
  {
    id: "projects_2",
    title: "MarcoNeo",
    image: "/assets/projects/raspberry.jpg  ",
    description: "Private payment system.",
    git_repo: "https://github.com/bde-tps/marconeo",
    md_file: "marconeo.md",
  },
  {
    id: "projects_3",
    title: "City vegetation analysis",
    image: "/assets/projects/multimodal.png",
    description: "Remote sensing project on vegetation analysis.",
    md_file: "multimodal.md",
    git_repo: "https://github.com/gatienc/City-vegetation-analysis",
  },
  {
    id: "projects_4",
    title: "Multimodal Product Classification",
    image: "/assets/projects/multimodal.png",
    description: "Classifying products using multiple modalities.",
    md_file: "multimodal.md",
    git_repo:
      "https://github.com/gatienc/multimodal_product_data_classification", // Updated repository link
  },
  {
    id: "projects_6",
    title: "Emoji NLP",
    image: "/assets/projects/emoji2vec.png",
    description:
      "Natural Language Processing project on research about emoji vectorization.",
    md_file: "emoji2vec.md",
    git_repo: "https://github.com/gatienc/emoji2vec_pytorch",
  },
  {
    id: "projects_7",
    title: "3D freelancing",
    image: "/assets/projects/manufacture_des_tabacs.jpg",
    description: "3D modeling and printing project",
    md_file: "manufacture_des_tabacs.md",
    git_repo: "",
  },
  {
    id: "projects_8",
    title: "Detection Glasses",
    image: "/assets/projects/emoji2vec.png",
    description: "Glasses-mounted device for detecting objects",
    md_file: "",
    git_repo: "",
  },
];
const ProjectComponent = (data) => {
  return (
    <div
      className="ProjectCard"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.9) 30%, rgba(255,255,255,0) 80%),url(${data.image})`,
      }}
    >
      <section className="ProjectCard_bottom">
        <h3>{data.title}</h3>
        {data.description}
      </section>
    </div>
  );
};

const Projects = () => {
  var project_list = projects_data.map((data) => {
    return (
      <Modal
        key={data.id}
        component={<ProjectComponent key={data.id} {...data} />}
        data={data}
      />
    );
  });

  return <div className="ProjectsContainer">{project_list}</div>;
};
export default Projects;
